import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {Link} from "gatsby"


function NotFoundPage(){
const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
return (
  <Layout>
    <SEO title="Страница не существует" />
    <section className="hero">
      <div className="hero-image">
          <Img fluid={data.heroImage.childImageSharp.fluid} /></div>
      <div className="wrap">
        <div
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out"
          data-sal-duration="500ms"
        >
          <h1>
            Страница
            <br />
            <span>не найдена</span>
          </h1>
          <div>
            <h2>Возможно вам дали неверный адрес</h2>
          </div>

          <Link to="/" className="button-paw">Оставить заявку</Link>
        </div>
      </div>
    </section>
  </Layout>
)

}

export default NotFoundPage
